export default {
  space: [0, 4, 8, 16, 24, 32, 40, 56, 72],
  fonts: {
    body: '"CircularStd", sans-serif',
    heading: 'inherit',
  },
  fontSizes: [12, 14, 16, 20, 24, 28, 32, 40, 48, 56],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.3,
  },
  colors: {
    white: '#ffffff',
    gray1: '#f9f7f1',
    gray2: '#e0ded9',
    gray3: '#c8c6c1',
    gray4: '#656360',
    black: '#1c1918',
    primary: '#1c1918',
    blue: '#98d1f1',
    purple: '#cbbde6',
    pink: '#ee80c1',
    orange: '#ef9645',
    green: '#55af7b',
    yellow: '#ffde59',
    text: '#656360',
    background: '#ffffff',
  },
  radii: [4, 8, 12, 16, 20, 24, 28, 32],
  sizes: {
    container: '1200px',
  },
  text: {
    default: {
      mt: 0,
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      color: 'black',
      mt: 0,
      mb: 2,
    },
  },
  buttons: {
    primary: {
      backgroundColor: 'primary',
      color: 'white',
      fontFamily: 'body',
      borderRadius: 1,
    },
    secondary: {
      backgroundColor: 'white',
      borderColor: 'primary',
      borderStyle: 'solid',
      borderWidth: '2px',
      color: 'primary',
      fontFamily: 'body',
      borderRadius: 1,
      transition: 'all 0.3s',
      '&:focus': {
        borderColor: 'primary',
      },
      '&:hover': {
        backgroundColor: 'primary',
        color: 'white',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      a: {
        color: 'primary',
      },
    },
    h1: {
      variant: 'text.heading',
      fontSize: 7,
      mb: 3,
    },
    h2: {
      variant: 'text.heading',
      fontSize: 6,
      mb: 3,
    },
    h3: {
      variant: 'text.heading',
      fontSize: 5,
      mb: 3,
    },
    h4: {
      variant: 'text.heading',
      fontSize: 4,
      mb: 3,
    },
    h5: {
      variant: 'text.heading',
      fontSize: 3,
      mb: 2,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 2,
      mb: 2,
    },
    ul: {
      my: 5,
      px: 4,
    },
    li: {
      my: 3,
    },
    a: {
      color: 'primary',
    },
    p: {
      mt: 0,
      mb: 5,
    },
  },
};
