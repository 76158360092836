// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-category-sanity-category-slug-current-js": () => import("./../../../src/pages/blog/category/{SanityCategory.slug__current}.js" /* webpackChunkName: "component---src-pages-blog-category-sanity-category-slug-current-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-sanity-post-slug-current-js": () => import("./../../../src/pages/blog/{SanityPost.slug__current}.js" /* webpackChunkName: "component---src-pages-blog-sanity-post-slug-current-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

